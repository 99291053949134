.Heading{  
  color:white;  
  font-size: 100%;
  flex-wrap:'wrap';
}
.Loader
{  
  margin-top:15%;
  margin-left:45%;
  margin-right: 45%;
  /* background-color: blue; */
  animation: rotation 1s ;
  font-size: 4rem;
  color:white;
  animation: rotation 2s infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* button{
  background-color:#769456;
  border-radius: 12px;  
  color:white;
  font-size:xx-large;
  border: 2px solid #769456; 
  padding: 2% 4%;
  margin:5%;
  cursor:pointer;
} */

a{
  text-decoration: none;
  color:#769456;
}
body{
  background-color: 
  #302e2a;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}





html {
  font-family: "Open Sans", sans-serif;
}


 button{
    padding: 1em 2.1em 1.1em;
    border-radius: 3px;
    margin: 8px 8px 8px 8px;
    color: #fbdedb;
    background-color: #fbdedb;
    display: inline-block;
    background: #769456;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: sans-serif;    
    font-weight: 800;    
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    position: relative;
    border-radius: 50em !important;
}


.Prev
{
  float:left;
}
.Next
{
  float:right;
}
.NextButton{
  float:right;
}
@media (max-width:1024px) 
{
  .BoardButtons button{
    font-size: 0.8em;
  }
.FormContainer{
  margin-top:10%;
  text-align: center;
}
  .InfoContainer
{
  text-align: center;
  color:#769456;
}

.InfoContainer span{ 
 font-size:xx-large;
}
button[type="submit"]
{
  font-size: 1.1em;    
}

}
@media (min-width:1024px) {
  
  .FormContainer
  {      
    margin-top:1%;
    text-align: center;
    
  }
.ChessBoardContainer
{      
  margin-top:0;
  margin-left:33%;
  margin-right:33%;  
}

    button[type="submit"]{
      background-color:#769456;
      border-radius: 12px;  
      color:white;
      font-size:0.9em;
      border: 2px solid #769456; 
      cursor:pointer;  
    }

    .Heading{
      font-size: 1.2em;
      display: inline;
    }
    .NextButton button{
      font-size: 1em;
      float: right;
    }

}


button:hover, button:focus {
    opacity: 0.8;
}
button:active {
    -webkit-transform: scale(0.80);
    -moz-transform: scale(0.80);
    -ms-transform: scale(0.80);
    -o-transform: scale(0.80);
    transform: scale(0.80);
}
button.block {
    display: block !important;
}
button.circular {
  border-radius: 50em !important;
}
.Chance
{  
  /* margin-left: 80%; */  
 font-size:1rem;  
 color:white;
}
.NotChance
{
  /* margin-left: 80%; */
  font-size:1rem;  
  color:white;
}
.NotChance span{
  float:right; 
  background-color: white;
  color:black;

}
.Chance span{
  float:right;
  background-color: black;
  color:white;
}

.InfoContainer{
  color:black;
}
*
{
  margin:0;
} 